<template>
  <div>
    <!-- <v-row no-gutters class="d-flex align-center mx-4 mt-4">
      <v-col cols="12" sm>
        <v-file-input
          label="Adjuntar ficheros"
          filled
          hide-details="auto"
          v-model="newFile"
          dense
          :rules="[
            (value) =>
              !value ||
              value.size < 15000000 ||
              'El archivo no puede superar las 15MB',
          ]"
        ></v-file-input>
      </v-col>
      <v-col cols="12" sm="auto" class="ml-1 mt-1 mt-sm-0">
        <v-btn
          block
          :disabled="!newFile || newFile.size > 15000000"
          color="primary"
          large
          @click="subirDocumento"
          :loading="sendingFile"
        >
          <v-icon left>mdi-upload</v-icon>Subir
        </v-btn>
      </v-col>
    </v-row> -->

    <template v-if="!final || $root.acceso('EDITAR_ESTUDIOS')">
      <drag-and-drop v-model="newFile" multiple></drag-and-drop>
      <div class="d-flex align-center my-2">
        <v-divider class="mr-4"></v-divider>
        <v-btn
          :disabled="newFile.length == 0"
          color="primary"
          large
          @click="subirDocumento"
        >
          <v-icon left>mdi-upload</v-icon>
          Enviar</v-btn
        >
      </div>
    </template>
    <v-data-table :items="documentos" :headers="documentosHeader">
      <template v-slot:item.acciones="{ item }">
        <v-btn
          @click="verDocumento(item.name, true)"
          dark
          text
          color="secondary"
          :loading="downloadingFile"
        >
          <v-icon left>mdi-download</v-icon>Descargar
        </v-btn>
        <v-btn
          @click="verDocumento(item.name, false)"
          dark
          text
          color="secondary"
          :loading="downloadingFile"
        >
          <v-icon left>mdi-eye</v-icon>Ver
        </v-btn>
        <v-btn
          color="error"
          text
          small
          class="ml-1"
          @click="borrarDocumento(item.name)"
          v-if="!final || $root.acceso('EDITAR_ESTUDIOS')"
        >
          <v-icon left>mdi-delete</v-icon>Borrar
        </v-btn>
      </template>
      <template v-slot:item.name="{ item }">
        <v-chip
          color="transparent"
          text-color="secondary"
          class="text-button"
          outlined
          label
          >{{ item.name }}</v-chip
        >
      </template>
    </v-data-table>

    <v-bottom-sheet v-model="changeFileName.show" persistent>
      <v-card class="pa-4">
        <v-row>
          <v-col cols="12" class="col-sm-8 col-md-6 mx-auto">
            <v-card-title>¿Con qué nombre quieres guardarlo?</v-card-title>
            <v-text-field
              v-model="changeFileName.newName"
              filled
              dense
              hide-details="auto"
              :suffix="changeFileName.extension"
              :rules="[changeFileName.req]"
            ></v-text-field>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="changeFileName.reject(false)" text
                >Cancelar</v-btn
              >
              <v-btn color="primary" @click="changeFileName.resolve(true)" text
                >Aceptar</v-btn
              >
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-bottom-sheet>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import {
  downloadFile,
  parseDate,
  getSituacionIcon,
  timeAgo,
  jsonToCsv,
} from "@/utils/index.js";
import { req } from "@/utils/validations.js";
import extensions from "@/utils/mimeExtensions.js";

export default {
  components: {
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  props: {
    final: {
      type: Boolean,
      default: false,
    },
    idEstudio: Number | String,
  },

  data() {
    return {
      documentos: [],
      documentosHeader: [
        { text: "Nombre", value: "name" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      downloadingFile: false,
      sendingFile: false,
      newFile: [],
      changeFileName: {
        newName: "",
        extension: "",
        show: false,
        resolve: null,
        reject: null,
        req,
      },
    };
  },
  mounted() {
    this.getDocumentacion();
  },
  methods: {
    async subirDocumento() {
      if (!this.idEstudio) return;
      for (let f of this.newFile) {
        try {
          await this.changeFileNameF(f.name);
          this.changeFileName.show = false;
        } catch (e) {
          this.changeFileName.show = false;
          this.sendingFile = false;
          f = null;
          return;
        }
        f = new File([f], this.changeFileName.newName, {
          type: f.type,
        });

        let formData = new FormData();
        formData.append("idBorrador", this.idEstudio);
        formData.append(
          "documentoName",
          this.changeFileName.newName + this.changeFileName.extension
        );
        formData.append("documento", f);
        formData.append("final", this.final ? 1 : 0);

        try {
          this.sendingFile = true;
          await axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_URL}/subir_documentacion.php`,
            data: formData,
          });
          f = null;
          this.sendingFile = false;
          this.getDocumentacion();
        } catch (e) {
          if (e.response.status == 413) {
            this.$root.$emit(
              "snack",
              "El documento es demasiado grande para ser subido"
            );
            this.sendingFile = false;
            f = null;
            return;
          }
          this.$root.$emit("snack", "No se ha podido subir el archivo");
          this.sendingFile = false;
          f = null;
          return;
        }
      }

      this.newFile = [];
    },
    getDocumentacion() {
      if (!this.idEstudio) return;
      return new Promise((resolve, reject) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/subir_documentacion.php`,
          params: {
            idBorrador: this.idEstudio,
            final: this.final ? 1 : 0,
          },
        })
          .then(({ data }) => {
            this.documentos = Object.values(data).map((f) => ({ name: f }));
            this.$emit('input', this.documentos);
            resolve();
          })
          .catch((e) => {
            // console.trace(e);
            this.$root.$emit(
              "snack",
              "No se ha podido encontrar la documentación"
            );
            reject();
          });
      });
    },
    changeFileNameF(name) {
      return new Promise((resolve, reject) => {
        this.changeFileName.show = true;
        this.changeFileName.newName = name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.changeFileName.extension =
          "." + name.split(".")[name.split(".").length - 1];
        this.changeFileName.resolve = resolve;
        this.changeFileName.reject = reject;
      });
    },
    verDocumento(name, download) {
      if (!this.idEstudio) return;
      this.downloadingFile = true;
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/subir_documentacion.php`,
        responseType: "blob",
        params: {
          idBorrador: this.idEstudio,
          token: this.$store.getters.getJwtEmpresa,
          filename: name,
          final: this.final ? 1 : 0,
        },
      }).then((res) => {
        let extension = name
          .split(".")
          [name.split(".").length - 1].toLowerCase();

        let file = new File([res.data], name, {
          type: extensions[extension],
        });
        downloadFile(file, !download);
        this.downloadingFile = false;
        // window.open(fileURL);
      });
    },
    async borrarDocumento(name) {
      if (
        !this.idEstudio ||
        !(await this.$refs.confirm.open('Eliminar documento', '¿Estás seguro de que quieres eliminar este documento?', { color: 'error', confirmColor: 'error' }))

      )
        return;
      axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_API_URL}/subir_documentacion.php`,
        params: {
          idBorrador: this.idEstudio,
          token: this.$store.getters.getJwtEmpresa,
          filename: name,
          final: this.final ? 1 : 0,
        },
      })
        .then((res) => {
          this.getDocumentacion();
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido eliminar el archivo");
        });
    },
  },
};
</script>
